<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import * as Clients from "@/services/clients.js";
import appConfig from "@/app.config";
import Table from "@/components/elements/Tables";
import moment from "moment";
export default {
  page: {
    title: "Clients",
    meta: [{ name: "Clients", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Table },
  created() {
    Clients.GetAllUsers().then((data) => {
      this.tableData = data;
    });
  },

  data() {
    return {
      tableData: [],
      title: this.$t("menuitems.clients.text"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.clients.text"),
          href: "/clients/all",
        },
        {
          text: this.$t("all"),
          active: true,
        },
      ],

      filterOn: [],
      sortBy: "createdAt",

      fields: [
        { label: this.$t("name"), key: "display_name", sortable: true,formatter:(value)=>value ||'ــــــــ' },
        { label: this.$t("email"), key: "email", sortable: false,formatter:(value)=>value ||'ــــــــ' },
        { label: this.$t("phone"), key: "phone_number", sortable: false,formatter:(value)=>value ||'ــــــــ' },
        {label: this.$t("date"), key: "createdAt",
          formatter: (value) => {
            return moment(value).format("YYYY-MM-DD")||'ــــــــ';
          },
          filterByFormatted: true,
          sortable: true,
        },
      ],
    };
  },
  methods: {
    rowClicked(item) {
      this.$router.push({ path: "/client/" + item.id });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Table
      :tableData="tableData"
      :fields="fields"
      :sortBy="sortBy"
      :filterOn="filterOn"

      @rowClicked="rowClicked"
    />
  </Layout>
</template>
